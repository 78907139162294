<template>
  <gtLogin
    ref="$gtLogin"
    class="width_fill"
    :on-success="onSuccess"
    :has-google-login="false"
    :has-facebook-login="false"
    :use-login="useLogin"
    :form-item="formItem"
    @onFormDataChange="onFormDataChange"
  >
    <h1 slot="logo" class="title">
      <div class="drawer-login-title-wrapper">
        <span>欢迎来到</span>
        <span style="font-family: var(--font-family-Bold) !important">Under Armour</span>
      </div>
      <img
        v-if="!isMobile"
        class="drawer-login-banner"
        :src="require('@/assets/image/login/drawer-login-banner.png')"
        alt="drawer-login-banner"
        :aspect-ratio="`${400 / 200}`"
        srcset=""
      />
      <img
        v-else
        class="drawer-login-banner"
        :src="require('@/assets/image/login/drawer-login-banner-mobile.png')"
        alt="drawer-login-banner"
        :aspect-ratio="`${360 / 120}`"
        srcset=""
      />
    </h1>
    <div
      slot="form-bottom"
      :style="
        !isMobile
          ? {
              'margin-top': '-24px',
              'margin-bottom': '32px',
              'text-decoration': 'underline',
              'text-align': 'right'
            }
          : {
              'margin-top': '-12px',
              'margin-bottom': '24px',
              'text-decoration': 'underline',
              'text-align': 'right'
            }
      "
    >
      <code style="cursor: pointer" @click="handleForgetPassword">忘记密码?</code>
    </div>
    <div slot="submit-bottom" class="policys">
      <GtCheckbox v-model="agree" :class="{ showAgreeError: !agree && showAgreeError }">
        <Policy :agree="agree" :show-agree-error="showAgreeError"></Policy>
      </GtCheckbox>
      <!--<div
        v-if="!isMobile"
        slot="append"
        style="min-width: 80px; text-align: right; cursor: pointer; text-decoration: underline"
        @click="handleForgetPassword"
      >
        忘记密码?
      </div>-->
    </div>
    <template slot="submit-btn" slot-scope="{ handleSubmit }">
      <GtButton
        class="login_button"
        style="margin-left: 0"
        type="primary"
        size="medium"
        :loading="loading"
        :disabled="!(formData.account && formData.password) || loginErrorBtnDisabled"
        @click="handleClickSubmitBtn(handleSubmit)"
      >
        {{ btnMessage }}
      </GtButton>
      <GtButton
        v-if="needGuest"
        type="primary"
        size="medium"
        style="margin-bottom: 24px; margin-left: 0; width: 100%"
        @click="handleGuestMessage"
      >
        游客咨询
      </GtButton>
    </template>
  </gtLogin>
</template>

<script>
import {
  gtLogin,
  GtCheckbox,
  GtButton,
  GtMessageBox,
  GtImage,
  GtMessage
} from '@speedshop/template';
import { useLogin } from '@speedshop/sdk-api';
import { mapGetters } from 'vuex';
import { MessageBox } from 'element-ui';
import Policy from './policy.vue';
import { loginByAccount } from '@/graphql/login.gql';
import { setPublicUserData } from '@/utils/track';

export default {
  components: {
    GtImage,
    gtLogin,
    GtCheckbox,
    Policy,
    GtButton,
    GtMessageBox
  },
  props: {
    showGuest: {
      type: Boolean,
      default: false
    },
    fromButton: {
      type: String,
      default: ''
    }
  },
  computed: {
    ...mapGetters(['isMobile', 'isGuest', 'isLogin', 'isUserCompletedInfo']),
    needGuest() {
      return this.showGuest;
    }
  },
  provide() {
    return {
      reload: () => false
    };
  },
  inject: ['refreshLogin'],
  data() {
    const validatorAccount = (rule, value, callback) => {
      const emailPattern = this.commonConfig?.emailPattern;
      const mobilePattern = this.commonConfig?.phonePattern;
      if (!emailPattern.test(value) && !mobilePattern.test(value)) {
        callback(new Error('请输入正确的邮箱或手机号'));
      } else if (this.loginErrorTips) {
        callback(this.loginErrorTips);
      } else {
        callback();
      }
    };
    return {
      showSliding: false,
      btnMessage: '登录',
      loginErrorTips: '',
      loginErrorBtnDisabled: false,
      loading: false,
      extList: [],
      agree: false,
      showAgreeError: false,
      cacheUserData: {},
      useLogin: () => {
        const Login = useLogin();
        const _this = this;

        Login.submit = async params => {
          if (!_this.agree) {
            this.showAgreeError = true;
            return Promise.reject({});
          }

          const { deviceId, account: accountOrEmail, password } = params;
          try {
            const res = await Login.gqlClient.mutate(loginByAccount, {
              input: { accountOrEmail, password }
            });
            const {
              data: { data }
            } = res;

            console.log(data, '------done------');
            if (data.needBinding) {
              setTimeout(() => {
                _this.bindCheck(data);
              }, 500);
              return Promise.reject('需要绑定');
            }
            return data;
          } catch (e) {
            const code = (e && e.graphQLErrors && e.graphQLErrors[0].code) || '';
            const message = (e && e.graphQLErrors && e.graphQLErrors[0].message) || '';
            // graphQLErrors
            if (e.message.includes('User password unset')) {
              this.$eventBus.$emit('switchLogin', 'NoSetPassword');

              return Promise.reject(e.message);
            } else if (['10021001'].includes(code)) {
              // 根据登录响应去触发error
              const message = (e.message || '').replace(/GraphQL error: /g, '');

              this.loginErrorTips = message;

              this.$refs.$gtLogin &&
                this.$refs.$gtLogin.$refs.form &&
                this.$refs.$gtLogin.$refs.form.validate();

              return Promise.reject(e.message);
            } else if (['UA_login_error_count'].includes(code)) {
              const $login = this.$refs.$gtLogin;
              const $form = $login.$refs.form;

              $form && $form.validate();

              this.loginErrorTips = '账号/密码错误';

              $form.validateField('account');

              GtMessage({
                type: 'warning',
                customClass: 'drawer-toast-message',
                message
              });

              return Promise.reject('账号/密码错误');
            } else {
              if (['account_lockout_error'].includes(code)) {
                this.btnMessage = '验证码输入错误次数过多，账号锁定中...';
                this.loginErrorBtnDisabled = true;
              }
              return Promise.reject(e.message);
            }
          }
        };
        return Login;
      },
      formData: {},
      formItem: [
        {
          prop: 'account',
          label: '邮箱/手机号',
          type: 'input',
          style: 'width:100%',
          attrs: {
            placeholder: '邮箱/手机号',
            clearable: false
          },
          rules: [
            {
              required: true,
              message: '请输入邮箱或手机号',
              trigger: 'blur'
            },
            {
              validator: validatorAccount
            }
          ]
        },

        {
          prop: 'password',
          label: '密码',
          type: 'input',
          style: 'width:100%',
          attrs: {
            placeholder: '密码',
            showPassword: true,
            clearable: false
          },
          rules: [
            {
              required: true,
              message: '请输入密码',
              trigger: 'blur'
            }
          ]
        }
      ]
    };
  },
  methods: {
    handleGuestMessage() {
      this.$trackHelp.track('popupClick', {
        popup_name: this.needGuest ? '游客咨询' : '欢迎来到 UNDER ARMOUR',
        button_name: '游客咨询'
      });
      this.$customerService.open();
    },

    async onSuccess(data) {
      if (data && data.userCode) {
        this.$sensors.login(data.userCode);
        window.TINGYUN && window.TINGYUN.setUid(data.userCode);
        this.$trackHelp.track('registerResult', {
          register_content: '完成信息填写登录',
          register_method: '账号密码登录',
          is_success: true,
          button_name: '登录',
          page_source: this.$route.meta.title
        });
        await this.refreshLogin();
        this.bindCheck(data);
        this.btnMessage = '登录成功';
        // 设置神策用户公共属性
        setPublicUserData();
      } else {
        return Promise.reject();
      }
    },

    handleForgetPassword() {
      this.$emit('onForgetPassword');
    },

    async bindCheck(data) {
      // 需要验证手机号/邮箱
      if (data.needBinding) {
        const textName = data.needBinding === 'email' ? 'extListEmail' : 'extListPhone';
        sessionStorage.setItem(
          textName,
          JSON.stringify(
            data.extList?.filter(item => {
              return item !== '';
            })
          ) || '[]'
        );
        sessionStorage.setItem(
          'cacheUserData',
          JSON.stringify({
            accountOrEmail: data.accountOrEmail,
            password: data.password
          })
        );

        this.$parent.$parent.showSwitching = false;
        if (data.needBinding === 'mobile') {
          this.$emit('switchActive', { name: 'BindPhone' });
        } else {
          this.$emit('switchActive', { name: 'BindEmail' });
        }
      } else if (data.userAuthToken) {
        this.$emit('loginSuccess');
      }
    },

    async handleClickSubmitBtn(handleSubmit) {
      if (!handleSubmit) return;
      this.loading = true;

      this.$trackHelp.track('registerPageClick', {
        button_name: this.btnMessage,
        register_method: '账号密码登录',
        register_content: '注册登录'
      });

      await handleSubmit('forms')
        .catch(error => {
          this.$trackHelp.track('registerResult', {
            page_source: this.$route.meta.title,
            button_name: this.btnMessage,
            register_content: '完成信息填写登录',
            register_method: '账号密码登录',
            is_success: false,
            fail_reason: (error.graphQLErrors && error.graphQLErrors[0].message) || error
          });
        })
        .finally(() => {
          this.loading = false;
        });
    },

    onFormDataChange(data) {
      this.loginErrorTips = '';
      this.loginErrorBtnDisabled = false;
      this.btnMessage = '登录';
      this.formData = data;
    }
  }
};
</script>

<style lang="scss" scoped>
.policys {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
}
</style>
<style>
.login-message-box {
  padding: 0px 0 64px 0 !important;
  border-radius: 1px !important;
}

.login-message-box .el-message-box__header {
  padding-top: 64px !important;
}

.login-message-box .el-message-box__title {
  color: var(--main-color-ua-dark-grey, #14141e);
  text-align: center;

  font-size: 24px;
  font-style: normal;
  font-weight: 600;
  line-height: 30px;
  /* 125% */
}

.login-message-box .el-message-box__btns {
  display: flex;
  justify-content: center;
}

.login-message-box .el-message-box__btns .gt-button {
  width: 155px;
}

.login-message-box .el-message-box__btns .el-button--primary {
  background: var(--theme-color-primary) !important;
  color: var(--btn-font-color) !important;
}
</style>
