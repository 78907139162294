<template>
  <div class="width_fill ua-bind-phone">
    <no-ssr>
      <!--验证0-1-->
      <template v-if="bindType !== 2">
        <h1 class="ua-bind-phone__title">
          <i class="iconfont icon-a-24-1px-left-s" @click="handleGoBack"></i>
          请绑定手机号码
        </h1>
        <p
          class="ua-bind-phone__desc"
          v-html="bindType === 0 ? `为了给您提供更完整的会员服务,请绑定手机号码` : '请确认您的账号'"
        ></p>
        <p v-if="bindType === 1" class="tip">
          {{
            `该邮箱已绑定手机号码：
          ${extList[0] && extList[0].substr(0, 3)}****${extList[0] && extList[0].substr(7)}
          ，请验证手机号码以进行下一步`
          }}
        </p>
        <GtForm
          ref="bindType01Form"
          :rules="rules"
          :model="formData"
          :hide-required-asterisk="true"
          class="reset-password_form"
          size="medium"
        >
          <GtFormItem prop="mobile" :label="`${bindType === 0 ? '' : '完整'}手机号码`">
            <GtInput
              v-model.trim="formData.mobile"
              :placeholder="`${bindType === 0 ? '' : '完整'}手机号码`"
            />
          </GtFormItem>
          <GtFormItem prop="verificationCode" label="验证码">
            <GtVerifyInput
              v-model.trim="formData.verificationCode"
              :send-code="sendCode"
              placeholder="验证码"
              des="后重新发送"
              :disabled-btn="!phonePattern.test(formData.mobile) ? true : disabled"
              maxlength="6"
            />
          </GtFormItem>
        </GtForm>
        <nvcValid ref="nvcValid" @sendCodeSuccess="sendCodeSuccess"></nvcValid>
        <GtButton
          type="primary"
          size="large"
          class="width_fill"
          :disabled="
            !(commonConfig.phonePattern.test(formData.mobile) && formData.verificationCode)
          "
          @click="bind"
        >
          绑定
        </GtButton>
      </template>

      <!--二选一-->
      <template v-else>
        <p class="ua-bind-phone__desc">
          {{ step === 0 ? '请确认您的账号' : step === 1 && nvcVal ? '验证码已发送' : '' }}
        </p>
        <p class="tip">
          {{
            step === 0
              ? '请选择账号需要绑定的唯一手机'
              : step === 1 && nvcVal
              ? `验证码已发送至手机：${formData2.mobile}，请注意查收`
              : ''
          }}
        </p>
        <GtForm v-show="step === 0">
          <GtFormItem>
            <GtRadioGroup v-model="formData2.mobile">
              <GtRadio v-for="(item, index) in extList" :key="index" :label="item">
                {{ item }}
              </GtRadio>
            </GtRadioGroup>
          </GtFormItem>
          <GtButton type="primary" size="large" class="width_fill" @click="goNext">
            发送验证码
          </GtButton>
        </GtForm>

        <GtForm
          v-show="step === 1"
          ref="vercode"
          :rules="rules"
          :model="formData2"
          :hide-required-asterisk="true"
          class="reset-password_form"
          size="medium"
        >
          <GtFormItem prop="verificationCode" label="验证码">
            <GtVerifyInput
              ref="sendCode2"
              v-model.trim="formData2.verificationCode"
              :send-code="sendCode2"
              placeholder="验证码"
              des="后重新发送"
              :disabled-btn="disabled"
              maxlength="6"
            />
          </GtFormItem>
          <nvcValid ref="nvcValid2" @sendCodeSuccess="sendCodeSuccess"></nvcValid>
          <GtButton type="primary" size="large" class="width_fill" @click="bind2">验证</GtButton>
        </GtForm>
      </template>
    </no-ssr>
  </div>
</template>

<script>
import {
  GtForm,
  GtFormItem,
  GtButton,
  GtInput,
  GtVerifyInput,
  GtRadio,
  GtRadioGroup,
  GtMessage,
  GtMessageBox,
  pageMixinCommon
} from '@speedshop/template';
import { mapGetters } from 'vuex';
import {
  useRegister,
  useResetPassword,
  useLogin,
  useOrder,
  useShopCart,
  useHome
} from '@speedshop/sdk-api';
import { MessageBox } from 'element-ui';

import nvcValid from './nvcValid.vue';
import { loginByAccount, speedSendNvcVerificationUACode } from '@/graphql/login.gql';
export default {
  components: {
    GtForm,
    GtFormItem,
    GtButton,
    GtInput,
    GtVerifyInput,
    GtRadioGroup,
    GtRadio,
    GtMessage,
    GtMessageBox,
    nvcValid
  },
  mixins: [pageMixinCommon],
  inject: ['refreshLogin', 'validationCode'],
  props: {
    extList: {
      type: Array,
      default: () => {
        return process.client ? JSON.parse(sessionStorage.getItem('extListPhone')) || [] : [];
      }
    },
    cacheUserData: {
      type: Object,
      default: () => {
        return process.client ? JSON.parse(sessionStorage.getItem('cacheUserData')) || {} : {};
      }
    }
  },
  data() {
    return {
      nvcVal: '',
      sendType: '',
      disabled: false,
      step: 0,
      agree: false,
      phonePattern: /^1[3-9]\d{9}$/,
      formData: {
        mobile: '',
        verificationCode: ''
      },
      formData2: {
        mobile: '',
        verificationCode: ''
      },
      rules: {
        mobile: [
          {
            required: true,
            message: '请输入手机号码',
            trigger: 'blur'
          },
          {
            required: true,
            pattern: this?.commonConfig?.phonePattern,
            message: '输入正确的手机号',
            trigger: 'blur'
          }
        ],
        verificationCode: [
          {
            required: true,
            message: '验证码',
            trigger: 'change'
          },
          {
            required: true,
            validator: (rule, value, callback) => {
              if (value.trim() === '') {
                callback(new Error('请输入验证码'));
              } else {
                callback();
              }
            },
            trigger: 'change'
          }
        ]
      }
    };
  },
  computed: {
    ...mapGetters(['isMobile']),
    bindType() {
      const data = this.extList.filter(item => {
        return item !== '';
      });
      return data.length;
    }
  },
  mounted() {
    this.$trackHelp.track('registerPageShow', {
      belong_page: '确认账号 -- 手机号'
    });
  },
  methods: {
    async sendCode() {
      this.$trackHelp.track('registerPageClick', {
        belong_page: '确认账号 -- 手机号',
        button_name: '发送验证码'
      });
      const resetPassword = useResetPassword();
      try {
        if (this.sendType !== 'nvcVal') {
          const valid = await this.validationCode(speedSendNvcVerificationUACode, {
            accountOrEmail: this.formData.mobile
          });
          if (!valid) {
            this.disabled = true;
            this.$refs.nvcValid && (await this.$refs.nvcValid.showNvc());
            GtMessage({
              type: 'warning',
              customClass: 'drawer-toast-message',
              message: '滑动验证后方可发送验证码'
            });
            return Promise.reject('');
          }
          this.disabled = false;
        } else {
          this.disabled = false;
          const Login = useRegister();
          await Login.gqlClient.mutate(speedSendNvcVerificationUACode, {
            input: {
              accountOrEmail: this.formData.mobile,
              nvcVal: this.nvcVal
            }
          });
          this.sendType = '';
        }
      } catch (error) {
        console.log(error);
      }
    },
    async bind() {
      const Login = useLogin();
      const { accountOrEmail = '', password = '' } = this.cacheUserData;
      const { mobile = '', verificationCode = '' } = this.formData;

      this.$refs.bindType01Form.$children[0].validate(async valid => {
        if (valid) {
          try {
            this.$trackHelp.track('registerPageClick', {
              belong_page: '确认账号 -- 手机号',
              button_name: '绑定'
            });

            const res = await Login.gqlClient.mutate(loginByAccount, {
              input: { accountOrEmail, password, mobile, verificationCode }
            });
            const Data = res.data?.data;
            const userAuthToken = Data?.userAuthToken;
            if (userAuthToken) {
              localStorage.setItem('token', userAuthToken);
              localStorage.setItem('userInfo', JSON.stringify({ ...res.data?.data }));

              this.$cookies.remove('token');
              this.$cookies.remove('AUTH-TOKEN');
              this.$cookies.set('token', userAuthToken);
              this.$cookies.set('AUTH-TOKEN', userAuthToken);
              this.$cookies.remove('guestId');
              await useShopCart().tradeMergeCart();
              localStorage.removeItem('guestId');
              await this.refreshLogin();

              this.$emit('LoginShow', { dialogVisible: false });
            }
          } catch (error) {
            console.log(error);
          }
        }
      });
    },

    async sendCode2() {
      try {
        if (this.sendType !== 'nvcVal') {
          const valid = await this.validationCode(speedSendNvcVerificationUACode, {
            accountOrEmail: this.formData2.mobile
          });
          if (!valid) {
            this.disabled = true;
            this.$refs.nvcValid2 && (await this.$refs.nvcValid2.showNvc());
            GtMessage({
              type: 'warning',
              customClass: 'drawer-toast-message',
              message: '滑动验证后方可发送验证码'
            });
            return Promise.reject('');
          }
          this.disabled = false;
        } else {
          this.disabled = false;

          const Login = useRegister();
          await Login.gqlClient.mutate(speedSendNvcVerificationUACode, {
            input: {
              accountOrEmail: this.formData2.mobile,
              nvcVal: this.nvcVal
            }
          });
          this.sendType = '';
        }
      } catch (error) {
        console.log(error);
      }
    },
    goNext() {
      const that = this;
      if (!that.formData2.mobile) {
        GtMessage.error('请选择手机号');
        return;
      }
      that.step = 1;

      setTimeout(() => {
        this.$trackHelp.track('registerPageClick', {
          belong_page: '确认账号 -- 手机',
          button_name: '发送验证码'
        });
        that.$refs.sendCode2.$children[0].$children[0].$children[0].handleStart();
      }, 100);
    },
    async bind2() {
      const that = this;
      const Login = useLogin();
      const { accountOrEmail, password } = this.cacheUserData;
      const { mobile, verificationCode } = this.formData2;

      this.$refs.vercode.$children[0].validate(async valid => {
        if (valid) {
          try {
            const res = await Login.gqlClient.mutate(loginByAccount, {
              input: { accountOrEmail, password, mobile, verificationCode }
            });
            const Data = res.data?.data;
            const userAuthToken = Data?.userAuthToken;
            if (userAuthToken) {
              localStorage.setItem('token', userAuthToken);
              localStorage.setItem('userInfo', JSON.stringify({ ...res.data?.data }));

              this.$cookies.remove('token');
              this.$cookies.remove('AUTH-TOKEN');
              this.$cookies.remove('guestId');
              this.$cookies.set('token', userAuthToken);
              this.$cookies.set('AUTH-TOKEN', userAuthToken);
              await useShopCart().tradeMergeCart();
              const userInfo = await this.refreshLogin();
              localStorage.removeItem('guestId');

              const forward = async function () {
                if (Data.firstLogin === 'true') {
                  // welcome
                  await that.$router.push({ name: 'AthleticClub' });
                } else {
                  await that.$router.push({ name: 'home' });
                }
              };

              // 选择EC手机号；提示您的邮箱账户已经与您选中的手机号绑定，与其他手机号的绑定关系取消
              if (this.extList.indexOf(mobile) === 1) {
                const box = new Proxy(MessageBox, {
                  get(obj, prop, value) {
                    if (typeof value === 'function') {
                      const message = new Proxy(obj[prop], {
                        apply(target, ctx, argList) {
                          return target(argList[0], argList[1], {
                            ...argList[2],
                            customClass: 'login-message-box',
                            confirmButtonClass:
                              'gt-button gt-message-box button el-button--primary',
                            cancelButtonClass: 'gt-button gt-message-box button'
                          });
                        }
                      });
                      return message;
                    } else {
                      return value;
                    }
                  }
                });
                box
                  .confirm(
                    '您的邮箱账户已经与您选中的手机号绑定，与其他手机号的绑定关系已取消',
                    '提示',
                    {
                      confirmButtonText: '确认',
                      type: '',
                      center: true,
                      showCancelButton: false
                    }
                  )
                  .then(async () => {
                    await forward();
                  })
                  .catch(async () => {
                    await forward();
                  });
              } else {
                await forward();
              }
            }
          } catch (error) {
            console.log(error);
          }
        }
      });
    },
    sendCodeSuccess(nvcVal) {
      this.disabled = false;
      setTimeout(() => {
        this.nvcVal = nvcVal;
        this.sendType = 'nvcVal';
        document.querySelector('.gt-verify-btn').click();
      }, 300);
    },
    handleGoBack() {
      this.$eventBus.$emit('switchLogin', 'loginByAccount');
    }
  }
};
</script>

<style lang="scss" scoped>
.ua-bind-phone {
  &__title {
    display: flex;
    color: var(--main-color-ua-dark-grey, #14141e);
    font-size: 24px;
    font-style: normal;
    font-weight: 600;
    line-height: 30px;
    i {
      cursor: pointer;
      font-size: 24px;
      margin-right: 8px;
      margin-top: 2px;
    }
    padding-bottom: 28px;
  }
  &__desc {
    margin-top: 28px;
    color: var(--secondary-color-ua-cool-grey-04, #50505a);
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 22px; /* 137.5% */
    text-transform: capitalize;
  }
}
</style>
<style lang="scss" scoped>
.login-drawer.login-container_mobile {
  .ua-bind-phone {
    &__title {
      font-size: 16px !important;
      line-height: 22px;
      padding-top: 20px;
      padding-bottom: 20px;
      i {
        cursor: pointer;
        font-size: 16px;
        margin-top: 2px;
        margin-right: 6px;
      }
    }
    &__desc {
      margin-top: 20px;
      font-size: 14px;
      line-height: 20px;
    }
  }
}
</style>
