<template>
  <GtDrawer
    :visible.sync="dialogVisible"
    :size="isMobile ? mobileHeight + 'px' : pcHeight + 'px'"
    :direction="isMobile ? 'btt' : 'rtl'"
    :append-to-body="true"
    :with-header="true"
    :modal="true"
    :wrapper-closable="true"
    :show-close="['loginByPhone', 'loginByAccount'].includes(active)"
    :class="[{ drawer_mobile: isMobile }, { 'hide-close': isNonmemberLogin }]"
  >
    <Theme class="login_theme login-drawer">
      <template slot="default"><div></div></template>
      <template slot="right">
        <components
          :is="active"
          :from-button="fromButton"
          :show-guest="isShowGuest"
          :show-nonmember="showNonmember"
          @loginSuccess="onSuccess"
          @hasLoginAndContinue="hasLoginAndContinue"
          @onForgetPassword="handleForgetPassword"
          @nonmenberDisplayChange="handleNonmemberDisplayChange"
          @switchActive="handleSwitchLogin"
        ></components>
        <switchLogin v-if="showLoginSwitch" :active.sync="active"></switchLogin>
      </template>
    </Theme>
  </GtDrawer>
</template>

<script>
import { GtDrawer, GtTabs, GtTabPane, GtImage } from '@speedshop/template';
import { useAccount } from '@speedshop/sdk-api';
import { mapGetters } from 'vuex';
import Theme from './theme';
import switchLogin from './switchLogin';
import loginByPhone from './loginByPhone.vue';
import loginByAccount from './loginByAccount.vue';
import ResetPassword from './resetPassword.vue';
import NoSetPassword from './noSetPassword.vue';
import CompleteUserInfo from './completeUserInfo.vue';
import BindPhone from './bindCrm/bindPhone.vue';
import BindEmail from './bindCrm/bindEmail.vue';
import { getUserInfo } from '@/graphql/user.gql';
import { debounce } from '@/utils/tools.ts';

export default {
  name: 'DrawerLogin',
  components: {
    GtImage,
    GtDrawer,
    Theme,
    switchLogin,
    loginByPhone,
    loginByAccount,
    GtTabs,
    GtTabPane,
    ResetPassword,
    NoSetPassword,
    CompleteUserInfo,
    BindPhone,
    BindEmail
  },
  provide: {
    async refreshLogin() {
      const accountInstance = useAccount();
      const {
        data: { userInfo }
      } = await accountInstance.gqlClient.query(getUserInfo);

      const originUserInfo = localStorage.getItem('userInfo');
      const parseInfo = JSON.parse(originUserInfo);
      localStorage.setItem('userInfo', JSON.stringify({ ...parseInfo, ...userInfo }));

      this.$store.commit('SET_USER_INFO', { ...parseInfo, ...userInfo });
      return userInfo;
    }
  },
  props: {
    visible: {
      type: Boolean,
      default: false
    },
    // 从哪个按钮点击过来的
    fromButton: {
      type: String,
      default: ''
    },
    defaultCallback: {
      type: Function,
      default: () => {}
    },
    showGuest: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      mobileHeight: 620,
      pcHeight: 480,
      dialogVisible: false,
      active: 'loginByPhone',
      isShowGuest: false,
      refresh: true,
      showNonmember: false,
      isNonmemberLogin: false
    };
  },
  computed: {
    ...mapGetters(['isMobile', 'isGuest', 'isLogin']),
    showLoginSwitch() {
      return !this.isNonmemberLogin;
    }
  },
  watch: {
    visible(val) {
      this.dialogVisible = val;
    },
    dialogVisible(val) {
      this.$emit('update:visible', val);
      if (val) {
        this.active = 'loginByPhone';
        this.$trackHelp.track('popupExpose', {
          popup_name: this.isShowGuest ? '游客咨询' : '欢迎来到 UNDER ARMOUR',
          belong_page: this.$route.meta.title,
          button_name: '游客咨询'
        });
        this.$trackHelp.track('registerPageShow', {
          belong_page: this.$route.meta.title
        });
      }
    }
  },

  mounted() {
    const setAndironFixedProblemFn = this.setAndironFixedProblem.bind(this);
    const debounceFn = debounce(setAndironFixedProblemFn, 1500);

    this.$eventBus.$on('drawLoginSuccessCallback', params => {
      if (this.refresh == true) {
        this.$router.go(0);
      }
    });

    this.$nextTick(() => {
      debounceFn();
    });
  },

  beforeMount() {
    this.$eventBus.$on('switchLogin', active => {
      this.active = active;
    });

    this.$eventBus.$on('LoginShow', params => {
      const { showLogin, showGuest = false, refresh, showNonmember = false } = params;
      this.dialogVisible = showLogin;
      this.isShowGuest = showGuest;
      this.showNonmember = showNonmember;
      this.refresh = refresh == undefined ? true : refresh;
    });
  },

  methods: {
    handleNonmemberDisplayChange(display) {
      this.isNonmemberLogin = display;
    },
    hasLoginAndContinue() {
      this.onSuccess();
    },
    setAndironFixedProblem() {
      // 获取浏览器可视区域高度
      const u = navigator.userAgent;
      const isIos = !!u.match(/\(i[^;]+;(U;)?CPU.+Mac OS X/);
      if (!isIos && this.isMobile) {
        // 键盘弹起事件
        const docmHeight = document.documentElement?.clientHeight || document.body?.clientHeight;
        const drawerHight = docmHeight >= 620 ? 620 : docmHeight;
        let resizeHeight = drawerHight;
        window.onresize = () => {
          resizeHeight = document.documentElement?.clientHeight || document.body?.clientHeight;
          if (resizeHeight < docmHeight) {
            this.$set(this, 'mobileHeight', resizeHeight);
          } else {
            this.$set(this, 'mobileHeight', drawerHight);
          }
        };
      }
    },
    onSuccess(data) {
      this.dialogVisible = false;
      this.defaultCallback && this.defaultCallback();
      this.$eventBus.$emit('drawLoginSuccessCallback', data);
    },

    handleForgetPassword() {
      this.active = 'ResetPassword';
    },

    handleSwitchLogin({ name }) {
      this.active = name;
    }
  }
};
</script>

<style lang="scss" scoped>
::v-deep .el-drawer__header {
  height: 64px;
  margin: 0 !important;
  padding: 0;
  align-items: center;
  position: absolute;
  top: 0;
  right: 10px;
  z-index: 99;
  .el-drawer__close-btn {
    position: absolute;
    top: 28px;
    right: 26px;
  }
  .el-drawer__close-btn {
    font-size: 24px;
  }
}
.gt-drawer.hide-close {
  ::v-deep.el-drawer__close-btn {
    display: none;
  }
}

.drawer_mobile {
  ::v-deep .el-drawer__header {
    height: 0;
    margin: 0;
    padding: 0;
    align-items: center;
    position: relative;
    .el-drawer__close-btn {
      position: absolute;
      top: 0;
      right: 4px;
      z-index: 1000;
      height: 66px;
      text-align: center;
      background: transparent;
      width: 20px;
      font-size: 18px;
    }
  }
}
</style>
