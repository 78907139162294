var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"icon-spite",class:{ 'dc-icon-spite': _vm.dontClick },on:{"click":_vm.linkTo}},[(_vm.imgName)?[_vm._t("icon",function(){return [_c('img',{attrs:{"src":_vm.imgName.startsWith('/')
            ? require(("@/assets" + _vm.imgName))
            : require(("@/assets/image/" + _vm.imgName)),"alt":("" + _vm.imgName),"width":_vm.width,"height":_vm.height}})]})]:[_vm._t("icon",function(){return [_c('i',{staticClass:"icon-img",style:({
          width: _vm.width,
          height: _vm.height,
          background: ("url(/icon@2x.png) no-repeat " + _vm.offsetX + " " + _vm.offsetY + "/342px 338px"),
          'background-size': '342px 338px'
        })})]})],_vm._v(" "),(_vm.label)?_c('div',{staticClass:"label",domProps:{"innerHTML":_vm._s(_vm.label)}}):_vm._e()],2)}
var staticRenderFns = []

export { render, staticRenderFns }