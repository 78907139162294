<template>
  <div class="width_fill ua-reset-password">
    <p class="title">
      <i @click="handleGoBack" class="iconfont icon-a-24-1px-left-s"></i>
      重置密码
    </p>
    <p v-if="step === 2 && isSentSuccess" class="tip">
      重置密码的验证码已发送至
      <br />
      您的{{ isEmail ? '邮箱' : '手机号码' }} {{ formData.accountOrEmail }}，{{
        isEmail ? '请登录邮箱查看' : '请注意查收'
      }}
    </p>

    <!-- 密码 -->
    <section v-if="step === 3" class="step-3">
      <GtForm
        ref="$resetForm"
        :rules="resetFromRules"
        :model="resetFormData"
        :hide-required-asterisk="true"
        class="reset-password_form"
        size="medium"
      >
        <GtFormItem prop="password" label="新密码" class="reset-password_form-new-password">
          <GtInput type="password" v-model.trim="resetFormData.password" placeholder="新密码" />
        </GtFormItem>
        <p class="reset-password_form-tips">
          <i>*</i>
          密码要求：8-16个字符,必须包含英文、数字、字符中的两种
        </p>
        <GtFormItem prop="confirmPassword" label="确认新密码">
          <GtInput
            type="password"
            v-model.trim="resetFormData.confirmPassword"
            placeholder="确认新密码"
          />
        </GtFormItem>
        <GtButton
          :loading="loading"
          class="width_fill"
          @click="handleResetPassword"
          type="primary"
          size="medium"
        >
          {{ btnMessage }}
        </GtButton>
      </GtForm>
    </section>

    <!-- 手机号与验证码  -->
    <GtForm
      v-else
      ref="$form"
      :rules="rules"
      :model="formData"
      :hide-required-asterisk="true"
      class="reset-password_form"
      size="medium"
    >
      <section v-if="step === 1" key="1" class="step-1">
        <GtFormItem prop="accountOrEmail" label="邮箱/手机号">
          <GtInput
            @change="handleChangeAccountOrEmail"
            v-model.trim="formData.accountOrEmail"
            placeholder="邮箱/手机号"
          />
        </GtFormItem>
        <nvcValid ref="nvcValid1" :key="step" @sendCodeSuccess="sendCodeSuccess"></nvcValid>
        <GtButton class="width_fill" type="primary" size="large" @click="handleNextStep">
          下一步
        </GtButton>
      </section>
      <section v-if="step === 2" key="2" class="step-2">
        <GtFormItem prop="captcha" label="验证码">
          <GtVerifyInput
            @input="handleChangeCaptcha"
            v-model.trim="formData.captcha"
            :send-code="sendCode"
            placeholder="验证码"
            des="后重新发送"
            :disabled-btn="disableded"
            maxlength="6"
          />
        </GtFormItem>
        <nvcValid ref="nvcValid2" @sendCodeSuccess="sendCodeSuccess"></nvcValid>
        <GtButton class="width_fill" type="primary" size="large" @click="handleVerify">
          下一步
        </GtButton>
      </section>
    </GtForm>
  </div>
</template>

<script>
import {
  gtResetPassword,
  GtForm,
  GtFormItem,
  GtInput,
  GtButton,
  GtVerifyInput,
  GtMessage
} from '@speedshop/template';
import { useRegister, useResetPassword } from '@speedshop/sdk-api';
import nvcValid from './bindCrm/nvcValid.vue';
import {
  speedShopVerifyMobileByVerificationCode,
  speedShopUpdatePassword,
  speedShopFindUser,
  speedSendNvcVerificationCode
} from '@/graphql/login.gql';
export default {
  components: {
    gtResetPassword,
    GtForm,
    GtFormItem,
    GtInput,
    GtButton,
    GtVerifyInput,
    nvcValid
  },
  inject: ['validationCode'],
  data() {
    const validatorAccount = (rule, value, callback) => {
      const emailPattern = this.commonConfig?.emailPattern;
      const mobilePattern = this.commonConfig?.phonePattern;
      if (!emailPattern.test(value) && !mobilePattern.test(value)) {
        callback(new Error('请输入正确的邮箱或手机号'));
      } else if (this.loginErrorTips) {
        callback(this.loginErrorTips);
      } else {
        callback();
      }
    };

    const validatorCaptcha = (rule, value, callback) => {
      if (value.trim() === '') {
        callback(new Error('请输入验证码'));
      } else if (this.validCodeError) {
        callback(new Error('请输入正确的验证码'));
      } else {
        callback();
      }
    };

    return {
      nvcVal: '',
      sendType: '',
      disableded: false,
      loading: false,
      btnMessage: '重置密码',
      loginErrorTips: '',
      validCodeError: false,
      formData: {
        accountOrEmail: '',
        captcha: ''
      },
      resetFormData: {
        password: '',
        confirmPassword: ''
      },
      rules: {
        accountOrEmail: [
          {
            required: true,
            message: '请输入邮箱或手机号',
            trigger: 'blur'
          },
          {
            validator: validatorAccount,
            trigger: 'blur'
          }
        ],
        captcha: [
          {
            required: true,
            message: '请输入验证码',
            trigger: 'blur'
          },
          {
            validator: validatorCaptcha,
            trigger: 'blur'
          }
        ]
      },
      resetFromRules: {
        password: [
          {
            required: true,
            message: '请输入密码',
            trigger: 'blur'
          },
          {
            required: true,
            pattern: this?.commonConfig?.passwordPattern,
            message: '请按要求输入新密码',
            trigger: 'blur'
          }
        ],
        confirmPassword: [
          {
            required: true,
            message: '请确认新密码',
            trigger: 'blur'
          },
          {
            required: true,
            trigger: 'blur',
            validator: (rule, value, callback) => {
              //
              if (value === this.resetFormData.password) {
                callback();
              } else {
                callback(new Error('两次输入的密码不一致，请重试'));
              }
            }
          },
          {
            required: true,
            pattern: this?.commonConfig?.passwordPattern,
            message: '请按要求输入密码',
            trigger: 'blur'
          }
        ]
      },
      step: 1,
      formItem: [
        {
          prop: 'password',
          type: 'input',
          style: 'width:100%',
          label: '密码',
          attrs: {
            placeholder: this.$t('sp.common.Password'),
            showPassword: true,
            clearable: false
          },
          rules: [
            {
              required: true,
              message: '请输入密码',
              trigger: 'blur'
            },
            {
              required: true,
              pattern: this?.commonConfig?.passwordPattern,
              message: '8-16个字符,必须包含英文、数字、字符中的两种',
              trigger: 'blur'
            }
          ]
        },
        {
          prop: 'confirmPassword',
          type: 'input',
          style: 'width:100%',
          label: '确认密码',
          attrs: {
            placeholder: this.$t('sp.login.New_Password'),
            showPassword: true,
            clearable: false
          },
          rules: [
            {
              required: true,
              message: '请确认密码',
              trigger: 'blur'
            },
            {
              required: true,
              trigger: 'blur',
              validator: (rule, value, callback) => {
                //
                if (value === this.formData.password) {
                  callback();
                } else {
                  callback(new Error('两次输入的密码不一致，请重试'));
                }
              }
            },
            {
              required: true,
              pattern: this?.commonConfig?.passwordPattern,
              message: '8-16个字符,必须包含英文、数字、字符中的两种',
              trigger: 'blur'
            }
          ]
        }
      ],
      useResetPassword: () => {
        const _this = this;
        console.log(_this);
        const resetPassword = useResetPassword();
        resetPassword.submit = async params => {
          const { password } = params;
          console.log(_this);
          const {
            data: { data }
          } = await resetPassword.gqlClient.mutate(speedShopUpdatePassword, {
            input: {
              password,
              accountOrEmail: _this.formData.accountOrEmail,
              code: _this.formData.captcha
            }
          });
          return data;
        };
        return resetPassword;
      },
      isSentSuccess: false,
      isResetSuccess: false
    };
  },
  computed: {
    isEmail() {
      const emailPattern = this.commonConfig?.emailPattern;
      return emailPattern.test(this.formData.accountOrEmail);
    }
  },
  async mounted() {
    this.$refs.nvcValid00 && (await this.$refs.nvcValid00.showNvc());
  },
  methods: {
    handleChangeCaptcha() {
      this.validCodeError = false;
    },
    handleNextStep() {
      const $form = this.$refs.$form.getForm();
      $form.validateField('accountOrEmail', async res => {
        if (!res) {
          const resetPassword = useResetPassword();
          const {
            data: { data }
          } = await resetPassword.gqlClient.query(speedShopFindUser, {
            input: {
              accountOrEmail: this.formData.accountOrEmail
            }
          });
          if (data) {
            this.loginErrorTips = '';
            if (this.sendType !== 'nvcVal' && !this.formData.accountOrEmail.includes('.com')) {
              const valid = await this.validationCode(speedSendNvcVerificationCode, {
                accountOrEmail: this.formData.accountOrEmail,
                type: '2'
              });
              if (!valid) {
                this.disableded = true;
                this.$refs.nvcValid1 && (await this.$refs.nvcValid1.showNvc());
                GtMessage({
                  type: 'warning',
                  customClass: 'drawer-toast-message',
                  message: '滑动验证后方可发送验证码'
                });
              } else {
                this.sendType = 'nvcVal';
                this.step = 2;
                this.$nextTick(() => {
                  document.querySelector('.gt-verify-btn').click();
                });
              }
            } else {
              this.step = 2;
              this.$nextTick(() => {
                document.querySelector('.gt-verify-btn').click();
              });
            }
          } else {
            this.loginErrorTips = '该账号不存在';

            $form.validateField('accountOrEmail');
          }
        }
      });
    },
    async sendCode() {
      try {
        if (this.sendType !== 'nvcVal') {
          const valid = await this.validationCode(speedSendNvcVerificationCode, {
            accountOrEmail: this.formData.accountOrEmail,
            type: '2'
          });
          if (!valid) {
            this.disableded = true;
            this.$refs.nvcValid2 && (await this.$refs.nvcValid2.showNvc());
            GtMessage({
              type: 'warning',
              message: '滑动验证后方可发送验证码',
              customClass: 'drawer-toast-message'
            });
            return Promise.reject('');
          }
          this.disableded = false;
        } else {
          this.disableded = false;

          const Login = useRegister();

          await Login.gqlClient.mutate(speedSendNvcVerificationCode, {
            input: {
              accountOrEmail: this.formData.accountOrEmail,
              type: '2',
              nvcVal: this.nvcVal
            }
          });
          this.sendType = '';
        }
      } catch (error) {
        console.log(error);
        GtMessage({
          type: 'error',
          message: error.message,
          customClass: 'drawer-toast-message'
        });
      }
      this.isSentSuccess = true;
    },
    handleVerify() {
      this.validCodeError = false;
      const $form = this.$refs.$form.getForm();
      $form.validateField('captcha', async res => {
        if (!res) {
          const resetPassword = useResetPassword();
          const input = {
            accountOrEmail: this.formData.accountOrEmail,
            scenariosType: '04',
            verificationCode: this.formData.captcha
          };
          try {
            await resetPassword.gqlClient.mutate(speedShopVerifyMobileByVerificationCode, {
              input
            });
            this.step = 3;
          } catch (e) {
            const code = (e && e.graphQLErrors && e.graphQLErrors[0].code) || '';
            console.log('验证码error:', e);
            if (['20220317'].includes(code)) {
              this.validCodeError = true;

              $form.validateField('captcha');

              return Promise.reject('请输入正确的校验码');
            }
          }
        }
      });
    },
    handleChangeAccountOrEmail() {
      this.loginErrorTips = '';
    },
    onFormDataChange(data) {
      this.loginErrorTips = '';
      this.formData = { ...this.formData, ...data };
    },
    onSuccess(data) {
      this.$emit('onSuccess', data);
      this.btnMessage = '重置成功';
      this.handleGoBack();
    },
    async handleClickSubmitBtn(handleSubmit) {
      if (!handleSubmit) return;
      this.loading = true;
      await handleSubmit('resetForm').finally(() => {
        this.loading = false;
      });
    },
    sendCodeSuccess(nvcVal) {
      this.step = 2;
      this.disableded = false;
      setTimeout(() => {
        this.nvcVal = nvcVal;
        this.sendType = 'nvcVal';
        document.querySelector('.gt-verify-btn').click();
      }, 300);
    },
    async handleResetPassword() {
      const $form = this.$refs.$resetForm.getForm();
      let { password } = this.resetFormData;
      const resetPassword = useResetPassword();
      $form.validate(async valid => {
        if (valid) {
          try {
            this.loading = true;
            let data = await resetPassword.submit({
              password,
              accountOrEmail: this.formData.accountOrEmail,
              code: this.formData.captcha
            });
            console.log(data);
            if (data.success) {
              GtMessage({
                type: 'success',
                message: this.$t('sp.common.password_updated_successfully_tips')
              });
              $form.resetFields();
            }
            if (this.onSuccess) {
              this.onSuccess();
            }
          } catch (e) {
            return Promise.reject(e);
          } finally {
            this.loading = false;
          }
        }
      });
    },
    handleGoBack() {
      this.$emit('switchActive', {
        name: 'loginByAccount'
      });
    }
  }
};
</script>

<style lang="scss" scoped>
.ua-reset-password {
  .reset-password_form-tips {
    color: var(--secondary-color-ua-cool-grey-04, #50505a);
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
    margin-bottom: 24px;
    i {
      color: var(--color-waring, #ed2b00);
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: 20px;
    }
  }
  .reset-password_form-new-password {
    margin-bottom: 12px !important;
  }
  .title {
    font-size: 28px !important;
    flex-direction: row;
    padding-bottom: 28px;
    i {
      font-size: 24px;
      margin-right: 8px;
      margin-top: 4px;
      cursor: pointer;
    }
  }
}
</style>
<style lang="scss">
.login-drawer.login-container_mobile {
  .ua-reset-password {
    .reset-password_form-tips {
      font-size: 12px;
      line-height: 18px;
      margin-bottom: 16px;
      i {
        font-size: 12px;
        line-height: 18px;
      }
    }
    .reset-password_form-new-password {
      margin-bottom: 8px !important;
    }
    .title {
      font-size: 16px !important;
      line-height: 22px;
      padding-bottom: 20px;
      padding-bottom: 20px;
      i {
        cursor: pointer;
        font-size: 16px;
        margin-top: 2px;
        margin-right: 6px;
      }
    }
  }
}
</style>
