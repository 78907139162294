var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"hw-search none-sm"},[_c('div',{staticClass:"hw-search_title"},[_vm._v("没有找到你想要的？")]),_vm._v(" "),_c('div',{staticClass:"hw-search_input"},[_c('span',{class:['hw-search_input-placeholder', { 'is-hidden': !!_vm.searchKey }]},[_vm._v("搜索UA商品")]),_vm._v(" "),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.searchKey),expression:"searchKey"},{name:"click-track",rawName:"v-click-track:researchColumnClick",value:({
        reseach_button: '顶部搜索'
      }),expression:"{\n        reseach_button: '顶部搜索'\n      }",arg:"researchColumnClick"}],staticClass:"hw-search_input-inner",attrs:{"type":"text"},domProps:{"value":(_vm.searchKey)},on:{"keydown":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter")){ return null; }return _vm.onSearch.apply(null, arguments)},"input":function($event){if($event.target.composing){ return; }_vm.searchKey=$event.target.value}}}),_vm._v(" "),_c('div',{directives:[{name:"click-track",rawName:"v-click-track",value:({
        eventName: 'researchButtonClick',
        data: {
          reseach_button: '顶部搜索',
          key_word: _vm.searchKey,
          key_word_type: '主动搜索'
        },
        isCache: true
      }),expression:"{\n        eventName: 'researchButtonClick',\n        data: {\n          reseach_button: '顶部搜索',\n          key_word: searchKey,\n          key_word_type: '主动搜索'\n        },\n        isCache: true\n      }"}],staticClass:"hw-search_search-btn",on:{"!click":function($event){return _vm.onSearch.apply(null, arguments)}}},[_c('span',{staticClass:"icon iconfont"},[_vm._v("")])])]),_vm._v(" "),_c('div',{staticClass:"hw-search_words"},[_c('span',{staticClass:"hw-search_word-label"},[_vm._v("试试搜索")]),_vm._v(" "),_vm._l((_vm.list),function(item,index){return [_c('span',{key:index,staticClass:"hw-search_word-link"},[_c('span',{directives:[{name:"click-track",rawName:"v-click-track",value:({
            eventName: 'researchButtonClick',
            data: {
              reseach_button: '顶部搜索',
              key_word: item.hotword,
              key_word_type: '热门搜索'
            },
            isCache: true,
            beforeTrack: function () {
              _vm.$trackHelp.setUserActiveData('key_word_list', function (arr) { return arr.concat( [item.hotword]); });
            }
          }),expression:"{\n            eventName: 'researchButtonClick',\n            data: {\n              reseach_button: '顶部搜索',\n              key_word: item.hotword,\n              key_word_type: '热门搜索'\n            },\n            isCache: true,\n            beforeTrack: () => {\n              $trackHelp.setUserActiveData('key_word_list', arr => [...arr, item.hotword]);\n            }\n          }"}],on:{"!click":function($event){return _vm.onHotWord(item)}}},[_vm._v("\n          "+_vm._s(item.hotword)+"\n        ")]),_vm._v("\n        "+_vm._s(index < _vm.list.length - 1 ? ',' : '')+"\n      ")])]})],2)])}
var staticRenderFns = []

export { render, staticRenderFns }