<!--
*@bindEmail
*@author zhian.xiang
*@date 2023/7/6 14:43
-->
<template>
  <div class="width_fill ua-confirm-account">
    <!--二选一-->
    <h1 class="ua-confirm-account__title">
      <i class="iconfont icon-a-24-1px-left-s" @click="handleGoBack"></i>
      {{ step === 0 ? '请确认您的账号' : '验证码已发送' }}
    </h1>
    <p class="tip">
      {{
        step === 0
          ? '请选择账号需要绑定的唯一邮箱'
          : `验证码已发送至邮箱：${formData.email}，请注意查收`
      }}
    </p>
    <GtForm v-show="step === 0">
      <GtFormItem>
        <GtRadioGroup v-model="formData.email">
          <GtRadio v-for="(item, index) in extList" :key="index" :label="item">{{ item }}</GtRadio>
        </GtRadioGroup>
      </GtFormItem>
      <GtButton type="primary" size="large" class="width_fill" @click="goNext">发送验证码</GtButton>
    </GtForm>

    <GtForm
      v-show="step === 1"
      ref="vercode"
      :hide-required-asterisk="true"
      :rules="rules"
      :model="formData"
      class="reset-password_form"
      size="medium"
    >
      <GtFormItem prop="verificationCode" label="验证码">
        <GtVerifyInput
          ref="sendCode2"
          v-model.trim="formData.verificationCode"
          :send-code="sendCode2"
          placeholder="验证码"
          des="后重新发送"
          :disabled-btn="false"
          maxlength="6"
        />
      </GtFormItem>
      <GtButton type="primary" size="large" class="width_fill" @click="bind2">验证</GtButton>
    </GtForm>
  </div>
</template>

<script>
import {
  GtForm,
  GtFormItem,
  GtButton,
  GtInput,
  GtVerifyInput,
  GtRadio,
  GtRadioGroup,
  GtMessage,
  pageMixinCommon
} from '@speedshop/template';

import { useResetPassword, useLogin } from '@speedshop/sdk-api';
import { sendEmailUACode, bindUAMail } from '@/graphql/login.gql';
export default {
  components: {
    GtForm,
    GtFormItem,
    GtButton,
    GtInput,
    GtVerifyInput,
    GtRadioGroup,
    GtRadio,
    GtMessage
  },
  mixins: [pageMixinCommon],
  inject: ['refreshLogin'],
  props: {
    extList: {
      type: Array,
      default: () => {
        return process.client ? JSON.parse(sessionStorage.getItem('extListEmail')) || [] : [];
      }
    },
    cacheUserData: {
      type: Object,
      default: () => {
        return process.client ? JSON.parse(sessionStorage.getItem('cacheUserData')) || {} : {};
      }
    }
  },
  data() {
    return {
      step: 0,
      agree: false,
      formData: {
        email: '',
        verificationCode: ''
      },
      rules: {
        email: [
          {
            required: true,
            message: '邮箱账号',
            trigger: 'blur'
          },
          {
            required: true,
            pattern: this?.commonConfig?.emailPattern,
            message: '输入正确的邮箱',
            trigger: 'blur'
          }
        ],
        verificationCode: [
          {
            required: true,
            message: '验证码',
            trigger: 'change'
          },
          {
            required: true,
            validator: (rule, value, callback) => {
              if (value.trim() === '') {
                callback(new Error('请输入验证码'));
              } else {
                callback();
              }
            },
            trigger: 'change'
          }
        ]
      }
    };
  },
  computed: {
    bindType() {
      const data = this.extList.filter(item => {
        return item !== '';
      });
      return data.length;
    }
  },
  mounted() {
    this.$trackHelp.track('registerPageShow', {
      belong_page: '确认账号 -- 邮箱'
    });
    if (!this.extList.length) {
      this.$router.replace({ name: 'login' });
    } else {
      this.formData.email = this.extList[0];
    }
  },
  methods: {
    handleGoBack() {
      this.$eventBus.$emit('switchLogin', 'loginByAccount');
    },
    async sendCode2() {
      const resetPassword = useResetPassword();
      try {
        await resetPassword.gqlClient.mutate(sendEmailUACode, {
          input: {
            email: this.formData.email,
            type: '7'
          }
        });
      } catch (error) {
        console.log(error);
      }
    },
    goNext() {
      const that = this;
      if (!that.formData.email) {
        GtMessage.error('请选择邮箱');
        return;
      }
      that.step = 1;

      setTimeout(() => {
        this.$trackHelp.track('registerPageClick', {
          belong_page: '确认账号 -- 邮箱',
          button_name: '发送验证码'
        });
        that.$refs.sendCode2.$children[0].$children[0].$children[0].handleStart();
      }, 100);
    },
    async bind2() {
      const Login = useLogin();
      const { accountOrEmail, password } = this.cacheUserData;
      const { email, verificationCode } = this.formData;

      this.$refs.vercode.$children[0].validate(async valid => {
        if (valid) {
          try {
            this.$trackHelp.track('registerPageClick', {
              belong_page: '确认账号 -- 邮箱',
              button_name: '验证'
            });
            const res = await Login.gqlClient.mutate(bindUAMail, {
              email,
              verificationCode
            });
            const Data = res.data?.data;
            const userAuthToken = Data?.userAuthToken;
            if (userAuthToken) {
              this.$emit('LoginShow', { dialogVisible: false });
            }
          } catch (error) {
            console.log(error);
          }
        }
      });
    }
  }
};
</script>

<style lang="scss" scoped>
.ua-confirm-account {
  &__title {
    display: flex;
    color: var(--main-color-ua-dark-grey, #14141e);
    font-size: 24px;
    font-style: normal;
    font-weight: 600;
    line-height: 30px;
    i {
      cursor: pointer;
      font-size: 24px;
      margin-right: 8px;
      margin-top: 2px;
    }
    padding-bottom: 28px;
  }
  .el-radio {
    font-weight: normal;
  }
}
</style>
<style lang="scss" scoped>
.login-drawer.login-container_mobile {
  .ua-confirm-account {
    &__title {
      font-size: 16px !important;
      line-height: 22px;
      padding-bottom: 20px;
      padding-bottom: 20px;
      i {
        cursor: pointer;
        font-size: 16px;
        margin-top: 2px;
        margin-right: 6px;
      }
    }
  }
}
</style>
