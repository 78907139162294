<template>
  <gtLogin
    ref="login"
    class="width_fill"
    :form-item="formItem"
    :on-success="onSuccess"
    :has-google-login="false"
    :has-facebook-login="false"
    :use-login="useLogin"
    :is-login-with-mobile-code="true"
    :hide-required-asterisk="true"
    @onFormDataChange="onFormDataChange"
  >
    <h1 v-if="nonmember" slot="logo" :class="['title', { 'is-mobile': isMobile }]">
      <div :class="['drawer-login-nonmember__title']">
        <span class="back-btn" @click="hideNonmemberLogin">
          <i class="iconfont icon-a-16-1px-left-s"></i>
        </span>
        <span class="drawer-login-nonmember__title-text">以非会员身份继续</span>
      </div>
      <div class="drawer-login-nonmember__message">
        请填写并验证您的手机号码，为您创建账户、与您联络或提供订单相关服务
      </div>
    </h1>
    <h1 v-else slot="logo" class="title">
      <div class="drawer-login-title-wrapper">
        <span>欢迎来到</span>
        <span style="font-family: var(--font-family-Bold) !important">Under Armour</span>
      </div>
      <img
        v-if="!isMobile"
        class="drawer-login-banner"
        :src="require('@/assets/image/login/drawer-login-banner.png')"
        alt="drawer-login-banner"
        :aspect-ratio="`${400 / 200}`"
        srcset=""
      />
      <img
        v-else
        class="drawer-login-banner"
        :src="require('@/assets/image/login/drawer-login-banner-mobile.png')"
        alt="drawer-login-banner"
        :aspect-ratio="`${360 / 120}`"
        srcset=""
      />
    </h1>

    <div slot="form-bottom">
      <nvcValid ref="nvcValid" @sendCodeSuccess="sendCodeSuccess"></nvcValid>
    </div>

    <template slot="submit-btn" slot-scope="{ handleSubmit }">
      <GtButton
        class="login_button"
        type="primary"
        size="medium"
        :loading="loading"
        :disabled="
          !(commonConfig.phonePattern.test(formData.accountOrEmail) && formData.verificationCode)
        "
        @click="handleClickSubmitBtn(handleSubmit)"
      >
        {{ nonmember ? '确认' : btnMessage }}
      </GtButton>
      <GtButton
        v-if="showGuest"
        type="primary"
        size="medium"
        style="margin-bottom: 24px; margin-left: 0; width: 100%"
        @click="handleGuestMessage"
      >
        游客咨询
      </GtButton>
      <div v-if="!nonmember && showNonmember" class="nonmember-btn__wrapper">
        <div class="nonmember-btn" @click="goContinue">
          <span>以非会员身份继续</span>
          <i class="iconfont icon-a-16-1px-right-s"></i>
        </div>
      </div>
    </template>

    <div slot="submit-bottom">
      <GtCheckbox v-model="agree" :class="{ showAgreeError: !agree && showAgreeError }">
        <Policy
          :agree="agree"
          :show-agree-error="showAgreeError"
          :include-member-policy="!nonmember"
        ></Policy>
      </GtCheckbox>
    </div>
  </gtLogin>
</template>

<script>
import {
  // GtImage,
  gtLogin,
  // GtForm,
  // GtFormItem,
  // GtVerifyInput,
  GtCheckbox,
  GtMessage,
  GtButton
} from '@speedshop/template';
import { useLogin, useRegister } from '@speedshop/sdk-api';
import { mapGetters } from 'vuex';
import { asyncGtMessage } from '../asyncComponent';
import Policy from './policy.vue';
import nvcValid from './bindCrm/nvcValid.vue';
import { registerOrLoginByMobile, speedSendNvcVerificationCode } from '@/graphql/login.gql';
import { setPublicUserData } from '@/utils/track';

export default {
  components: {
    // GtImage,
    gtLogin,
    // GtForm,
    // GtFormItem,
    // GtVerifyInput,
    GtCheckbox,
    Policy,
    GtButton,
    nvcValid
  },
  provide() {
    return {
      reload: () => false
    };
  },
  inject: ['refreshLogin', 'validationCode'],
  props: {
    showGuest: {
      type: Boolean,
      default: false
    },
    showNonmember: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      nvcVal: '',
      sendType: '',
      disableded: false,
      btnMessage: '注册/登录',
      loading: false,
      extList: [],
      cacheUserData: {},
      agree: false,
      validCodeError: false,
      showAgreeError: false,
      nonmember: false,
      useLogin: () => {
        const Login = useLogin();
        const _this = this;
        Login.registerOrLoginByMobile = async params => {
          if (!_this.agree) {
            this.showAgreeError = true;
            return Promise.reject(new Error('未同意隐私政策'));
          }
          _this.validCodeError = false;

          try {
            const { accountOrEmail, verificationCode, mobile } = params;
            const res = await Login.gqlClient.query(registerOrLoginByMobile, {
              input: {
                accountOrEmail,
                verificationCode,
                mobile,
                policyFlag: Number(_this.agree),
                protocolFlag: !this.nonmember
              }
            });
            const {
              data: { data }
            } = res;
            // 若非会员登录，且返回了crm标识为true，则表示已经注册过crm
            if (this.nonmember && data.protocolFlag) {
              await asyncGtMessage({
                customClass: 'drawer-toast-message',
                message: '您的手机号曾注册为会员，已为您自动登录'
              });
            }

            return data;
          } catch (e) {
            const code = (e && e.graphQLErrors && e.graphQLErrors[0].code) || '';
            const message = (e && e.graphQLErrors && e.graphQLErrors[0].message) || '';

            if (['20220318'].includes(code)) {
              _this.validCodeError = true;

              const $login = this.$refs.login;
              const $form = $login.$refs.form;

              $form.validateField('verificationCode');

              return Promise.reject(new Error('请输入正确的校验码'));
            }

            if (['UA_login_error_count'].includes(code)) {
              _this.validCodeError = true;

              const $login = this.$refs.login;
              const $form = $login.$refs.form;

              $form.validateField('verificationCode');

              GtMessage({
                type: 'warning',
                customClass: 'drawer-toast-message',
                message
              });

              return Promise.reject(new Error('请输入正确的校验码'));
            }

            console.log(e);
          }
        };
        return Login;
      },
      formData: {},
      formItem: [
        {
          prop: 'accountOrEmail',
          label: '手机号码',
          type: 'input',
          style: 'width:100%',
          attrs: {
            placeholder: '手机号码'
          },
          rules: [
            {
              required: true,
              message: '请输入手机号',
              trigger: 'blur'
            },
            {
              required: true,
              pattern: this?.commonConfig?.phonePattern,
              message: '请输入正确的手机号',
              trigger: 'blur'
            }
          ]
        },
        {
          prop: 'verificationCode',
          label: '验证码',
          type: 'verify-input',
          style: 'width:100%',
          attrs: {
            des: '后重新发送',
            clearable: false,
            placeholder: '验证码',
            sendCode: async () => {
              const $login = this.$refs.login;
              const $form = $login.$refs.form;
              let res;

              await $form.validateField('accountOrEmail', async error => {
                if (error) {
                  res = false;
                } else {
                  res = true;
                }
              });

              if (!res) {
                return Promise.reject(new Error('请输入手机号'));
              } else {
                if (this.sendType !== 'nvcVal') {
                  this.$trackHelp.track('registerPageClick', {
                    button_name: '发送验证码',
                    register_method: '手机号快速登录',
                    register_content: '注册登录'
                  });
                  const valid = await this.validationCode(speedSendNvcVerificationCode, {
                    accountOrEmail: this.formData.accountOrEmail,
                    type: '1'
                  });
                  if (!valid) {
                    this.disableded = true;
                    this.$refs.nvcValid && (await this.$refs.nvcValid.showNvc());
                    GtMessage({
                      type: 'warning',
                      customClass: 'drawer-toast-message',
                      message: '请滑动验证后登录'
                    });
                    return Promise.reject(new Error('请滑动验证后登录'));
                  }
                  this.disableded = false;
                } else {
                  this.disableded = false;

                  const Login = useRegister();
                  this.$trackHelp.track('registerPageClick', {
                    button_name: '发送验证码',
                    register_method: '手机号快速登录',
                    register_content: '注册登录'
                  });

                  await Login.gqlClient.mutate(speedSendNvcVerificationCode, {
                    input: {
                      accountOrEmail: this.formData.accountOrEmail,
                      type: '1',
                      nvcVal: this.nvcVal
                    }
                  });

                  this.sendType = '';
                }

                return true;
              }
            },
            disabledBtn: formData => {
              const account = formData.accountOrEmail;
              this.formData.accountOrEmail = account;
              const pattern = this.commonConfig?.phonePattern;
              return !(account && pattern.test(account)) || this.disableded;
            }
          },
          rules: [
            {
              required: true,
              message: '请输入验证码',
              trigger: 'change'
            },
            {
              required: true,
              validator: (rule, value, callback) => {
                if (value.trim() === '') {
                  callback(new Error('请输入验证码'));
                } else if (this.validCodeError) {
                  callback(new Error('请输入正确的验证码'));
                } else {
                  callback();
                }
              },
              trigger: 'change'
            }
          ]
        }
      ]
    };
  },
  computed: {
    ...mapGetters(['isMobile', 'isGuest', 'isLogin', 'isUserCompletedInfo'])
  },
  methods: {
    goContinue() {
      if (this.isLogin && !this.isGuest) {
        this.$emit('hasLoginAndContinue');
      } else {
        this.showNonmemberLogin();
      }
    },
    showNonmemberLogin() {
      this.nonmember = true;
      this.$emit('nonmenberDisplayChange', this.nonmember);
    },
    hideNonmemberLogin() {
      this.nonmember = false;
      this.$emit('nonmenberDisplayChange', this.nonmember);
    },
    handleGuestMessage() {
      this.$trackHelp.track('popupClick', {
        popup_name: this.showGuest ? '游客咨询' : '欢迎来到 UNDER ARMOUR',
        button_name: '游客咨询'
      });
      this.$customerService.open();
    },
    async onSuccess(data) {
      if (data && data.userCode) {
        this.$sensors.login(data.userCode);
        window.TINGYUN && window.TINGYUN.setUid(data.userCode);
        this.$trackHelp.track('registerResult', {
          register_content: '注册登录',
          register_method: '手机号快速登录',
          is_success: true,
          button_name: '登录',
          page_source: this.$route.meta.title
        });
        await this.refreshLogin();
        this.bindCheck(data);
        this.btnMessage = '登录成功';
        // 设置神策用户公共属性
        setPublicUserData();
      } else {
        return false;
      }
    },
    onFormDataChange(data) {
      this.validCodeError = false;
      this.formData = data;
    },
    handleForgetPassword() {
      this.$emit('onForgetPassword');
    },
    bindCheck(data) {
      // 需要验证邮箱
      if (data.needBinding) {
        sessionStorage.setItem(
          'extListEmail',
          JSON.stringify(
            data.extList?.filter(item => {
              return item !== '';
            })
          ) || '[]'
        );
        sessionStorage.setItem(
          'cacheUserData',
          JSON.stringify({
            accountOrEmail: data.accountOrEmail
          })
        );
        this.$parent.$parent.showSwitching = false;
        if (data.needBinding === 'mobile') {
          // 路由跳转都是sessionStorage
          this.$emit('switchActive', { name: 'BindPhone' });
        } else {
          this.$emit('switchActive', { name: 'BindEmail' });
        }
      } else if (data.userAuthToken) {
        this.$emit('loginSuccess');
      }
    },
    async handleClickSubmitBtn(handleSubmit) {
      if (!handleSubmit) return;
      this.loading = true;

      this.$trackHelp.track('registerPageClick', {
        button_name: this.btnMessage,
        register_method: '手机号快速登录',
        register_content: '注册登录'
      });

      //
      await handleSubmit('forms')
        .catch(error => {
          this.$trackHelp.track('registerResult', {
            page_source: this.$route.meta.title,
            button_name: this.btnMessage,
            register_content: '注册登录',
            register_method: '手机号快速登录',
            is_success: false,
            fail_reason: (error.graphQLErrors && error.graphQLErrors[0].message) || error
          });
        })
        .finally(() => {
          this.loading = false;
        });
    },
    sendCodeSuccess(nvcVal) {
      this.disableded = false;
      setTimeout(() => {
        this.nvcVal = nvcVal;
        this.sendType = 'nvcVal';
        document.querySelector('.gt-verify-btn').click();
      }, 300);
    }
  }
};
</script>

<style lang="scss" scoped>
.title {
  &.is-mobile {
    .drawer-login-nonmember__title-text {
      font-size: 16px;
      font-weight: 600;
      line-height: 22px;
      position: relative;
      top: -1px;
    }
    .drawer-login-nonmember__message {
      margin-top: calc(20px + 20px);
      font-size: 14px;
      font-weight: 400;
      line-height: 20px;
    }
    .back-btn {
      width: 16px;
      height: 16px;
      .iconfont {
        font-size: 16px;
      }
    }
  }
}
.drawer-login-nonmember__title {
  display: flex;
  align-items: center;
}
.back-btn {
  width: 24px;
  height: 24px;
  margin-right: 8px;
  display: flex;
  align-items: center;
  justify-content: center;
  user-select: none;
  cursor: pointer;
  .iconfont {
    font-size: 24px;
  }
}
.drawer-login-nonmember__title-text {
  font-size: 24px;
  font-weight: 600;
  line-height: 30px;
}
.drawer-login-nonmember__message {
  margin-top: calc(28px + 28px);
  font-size: 16px;
  font-weight: 400;
  line-height: 22px;
  color: #50505a;
  white-space: normal;
}
.nonmember-btn {
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  color: #50505a;
  user-select: none;
  cursor: pointer;
}
.nonmember-btn__wrapper {
  margin-top: -4px;
  display: flex;
  justify-content: center;
  margin-bottom: 16px;
}
</style>
