<template>
  <div class="hw-search none-sm">
    <div class="hw-search_title">没有找到你想要的？</div>
    <div class="hw-search_input">
      <span :class="['hw-search_input-placeholder', { 'is-hidden': !!searchKey }]">搜索UA商品</span>
      <input
        v-model="searchKey"
        v-click-track:researchColumnClick="{
          reseach_button: '顶部搜索'
        }"
        class="hw-search_input-inner"
        type="text"
        @keydown.enter="onSearch"
      />
      <div
        v-click-track="{
          eventName: 'researchButtonClick',
          data: {
            reseach_button: '顶部搜索',
            key_word: searchKey,
            key_word_type: '主动搜索'
          },
          isCache: true
        }"
        class="hw-search_search-btn"
        @click.capture="onSearch"
      >
        <span class="icon iconfont">&#xe899;</span>
      </div>
    </div>
    <div class="hw-search_words">
      <span class="hw-search_word-label">试试搜索</span>
      <template v-for="(item, index) in list">
        <span :key="index" class="hw-search_word-link">
          <span
            v-click-track="{
              eventName: 'researchButtonClick',
              data: {
                reseach_button: '顶部搜索',
                key_word: item.hotword,
                key_word_type: '热门搜索'
              },
              isCache: true,
              beforeTrack: () => {
                $trackHelp.setUserActiveData('key_word_list', arr => [...arr, item.hotword]);
              }
            }"
            @click.capture="onHotWord(item)"
          >
            {{ item.hotword }}
          </span>
          {{ index < list.length - 1 ? ',' : '' }}
        </span>
      </template>
    </div>
  </div>
</template>

<script>
import { useBasic } from '@speedshop/sdk-api';
import { mapGetters } from 'vuex';
import { queryHotWords } from '@/graphql/plp.gql';

export default {
  name: 'HotWordSearch',
  data() {
    return {
      list: [],
      searchKey: ''
    };
  },
  computed: {
    ...mapGetters(['isMobile', 'isGuest', 'isLogin'])
  },
  watch: {
    $route: {
      handler(val, oldVal) {
        if (val?.name === 'plpSearch') {
          this.searchKey = val.query.searchWord;
        } else {
          this.searchKey = '';
        }
      }
    }
  },
  mounted() {
    this.init();
    console.log('%c [  ]-90', 'font-size:13px; background:pink; color:#bf2c9f;');
  },
  methods: {
    async init() {
      const Basic = useBasic();
      const data = await Basic.gqlClient.query(queryHotWords, {
        type: 1
      });
      this.list = data.data.data.list.slice(0, 10);
    },
    onSearch() {
      if (this.searchKey) {
        this.$trackHelp.setUserActiveData('key_word_list', arr => [...arr, this.searchKey]);
      }
      const isHot = this.list.find(i => i.hotword === this.searchKey);
      if (isHot && isHot.linkUrl) {
        this.$router.push(isHot.linkUrl);
      } else {
        this.addHistoryData();
        const $el = window;
        if ($el) {
          $el.scrollTo(0, 0);
        }
        this.$router.push({
          name: 'plpSearch',
          query: { searchWord: this.searchKey, isHotKey: false, searchPos: 'Search-Bottom' }
        });
      }
    },
    onHotWord(item) {
      if (item.linkUrl) {
        const { href } = this.$router.resolve({
          path: item.linkUrl,
          query: {
            isHotKey: true,
            searchPos: 'Search-Bottom'
          }
        });
        if (this.searchKey) {
          this.$trackHelp.setUserActiveData('key_word_list', arr => [...arr, item.hotword]);
        }
        this.$router.push(href);
      } else {
        if (this.searchKey) {
          this.$trackHelp.setUserActiveData('key_word_list', arr => [...arr, item.hotword]);
        }
        this.$router.push({
          name: 'plpSearch',
          query: { searchWord: item.hotword, isHotKey: true, searchPos: 'Search-Bottom' }
        });
      }
    },
    addHistoryData() {
      if (this.searchKey.trim()) {
        const list = JSON.parse(localStorage.getItem('Cache_Search_Records')) || [];
        const flag = list.some(item => {
          return item === this.searchKey;
        });
        if (!flag) list.unshift(this.searchKey);

        localStorage.setItem('Cache_Search_Records', JSON.stringify(list));
      }
    }
  }
};
</script>

<style lang="scss" scoped>
.hw-search {
  max-width: 1032px;
  padding: 56px 20px 0;
  margin: 0 auto 79px;
}

.hw-search_title {
  font-size: 14px;
  color: #1d1d1d;
  line-height: 19px;
  margin-bottom: 24px;
}
.hw-search_input {
  border-bottom: 1px solid #d8d8d8;
  padding: 0 40px 16px 0;
  position: relative;
  margin-bottom: 24px;
}

.hw-search_input:focus-within .hw-search_input-placeholder,
.hw-search_input-placeholder.is-hidden {
  visibility: hidden;
}

.hw-search_input-placeholder {
  color: #d0d0d0;
  font-size: 32px;
  font-weight: 600;
  letter-spacing: -0.5px;
  line-height: 40px;
}

.hw-search_input-inner {
  width: calc(100% - 40px);
  border: none;
  height: 44px;
  padding: 0;
  font-size: 32px;
  color: #1d1d1d;
  position: absolute;
  left: 0;
  top: 0;
  background-color: transparent;
}

.hw-search_search-btn {
  position: absolute;
  right: 0;
  bottom: 14px;
  cursor: pointer;
  user-select: none;
  .icon {
    font-size: 24px;
  }
}

.hw-search_words {
  font-size: 12px;
  color: #5f5f5f;
  line-height: 16px;
  padding-right: 2px;
}

.hw-search_word-label {
  margin-left: 6px;
}
.hw-search_word-link {
  margin-left: 2px;
  span {
    cursor: pointer;
    text-decoration: underline;
  }
}
</style>
