<template>
  <div class="ua-unset-password">
    <p class="ua-unset-password__title" >
      <i class="iconfont icon-a-24-1px-left-s" @click="handleGoBack"></i>
      未设置密码
    </p>

    <div class="content-wrapper">
      <h3>账号暂时未设置密码</h3>
      <p>请使用手机号码和验证码登录后再设置密码</p>
      <GtButton
        class="login_by_phone_button"
        type="primary"
        size="medium"
        :loading="loading"
        @click="handleSwitchLoginByPhone"
      >
        手机号码登录
      </GtButton>
    </div>
  </div>
</template>
  
<script>
import { GtButton } from '@speedshop/template';

export default {
  name: 'NoSetPassword',
  components: {
    GtButton
  },
  data() {
    return {};
  },
  methods: {
    handleSwitchLoginByPhone() {
      this.$eventBus.$emit('switchLogin', 'loginByPhone');
    },
    handleGoBack() {
      this.$eventBus.$emit('switchLogin', 'loginByAccount');
    }
  }
};
</script>
  
<style lang="scss">
.ua-unset-password {
  padding: 0 40px 40px;
  width: 100%;
  &__title {
    display: flex;
    color: var(--main-color-ua-dark-grey, #14141e);
    font-size: 24px;
    font-style: normal;
    font-weight: 600;
    line-height: 30px; /* 125% */
    text-transform: capitalize;
    i {
      cursor: pointer;
      font-size: 24px;
      margin-top: 4px;
      margin-right: 8px;
    }
    padding-bottom: 32px;
  }
  .content-wrapper {
    margin-top: 32px;
    h3 {
      color: var(--main-color-ua-dark-grey, #14141e);
      text-align: center;
      font-size: 20px;
      font-style: normal;
      font-weight: 600;
      line-height: 26px; /* 130% */
      text-transform: capitalize;
    }
    p {
      padding-top: 8px;
      color: var(--secondary-color-ua-cool-grey-04, #50505a);
      text-align: center;
      font-size: 16px;
      font-style: normal;
      font-weight: 400;
      line-height: 22px; /* 137.5% */
      text-transform: capitalize;
    }
    .gt-button {
      width: 100%;
      margin-top: 32px;
    }
  }
}
</style>
<style lang="scss">
.login-drawer.login-container_mobile{
  .ua-unset-password {
    padding: 20px;
    width: 100%;
    &__title {
      font-size: 16px !important;
      line-height: 22px;
      padding-bottom: 20px;
      padding-bottom: 20px;
      i {
        cursor: pointer;
        font-size: 16px;
        margin-top: 2px;
        margin-right: 6px;
      }
    }
    .content-wrapper {
      margin-top: 20px;
      h3 {
        font-size: 16px;
        line-height: 22px;
      }
      p {
        padding-top: 8px;
        font-size: 14px;
        line-height: 20px; 
      }
      .gt-button {
        width: 100%;
        margin-top: 32px;
      }
    }
  }
}

</style>
  