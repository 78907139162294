import { render, staticRenderFns } from "./PdtCornerLabel.vue?vue&type=template&id=14702e3a&"
import script from "./PdtCornerLabel.vue?vue&type=script&lang=ts&"
export * from "./PdtCornerLabel.vue?vue&type=script&lang=ts&"
import style0 from "./PdtCornerLabel.vue?vue&type=style&index=0&lang=scss&layout=pc&description=%E7%BD%91%E9%A1%B5%E7%AB%AF%E6%A0%B7%E5%BC%8F&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports