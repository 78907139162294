<template>
  <div class="policy">
    <p>
      我已了解并接受Under Armour的
      <template v-if="includeMemberPolicy">
        <a href="/help/point-rule.htm" target="_black">会员政策</a>
        、
      </template>
      <a
        href="https://privacy.underarmour.com/s/article/Global-Privacy-Policy?language=zh_CN"
        target="_black"
      >
        隐私条款
      </a>
      及
      <a href="/help/terms-of-use" target="_black">使用条款</a>
    </p>
    <p v-if="showAgreeError && !agree" @click.prevent>
      <span class="policy-error">
        请同意
        <template v-if="includeMemberPolicy">会员政策、</template>
        隐私条款及使用条款
      </span>
    </p>
    <slot name="append"></slot>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';

export default {
  props: {
    agree: {
      type: Boolean,
      default: false
    },
    showAgreeError: {
      type: Boolean,
      default: false
    },
    includeMemberPolicy: {
      type: Boolean,
      default: true
    }
  },
  computed: {
    ...mapGetters(['isMobile', 'isGuest', 'isLogin'])
  }
};
</script>

<style lang="scss" scoped>
.policy {
  display: flex;
  justify-content: space-between;
  align-items: center;
  white-space: normal;
  flex-wrap: wrap;
  // max-width: calc(100% - 20px);
  a {
    color: var(--main-color-ua-dark-grey);
    font-style: normal;
    font-weight: 400;
    line-height: 20px;
    text-decoration-line: underline;
  }
}
.policy-error {
  color: var(--color-waring, #ed2b00);
  font-style: normal;
  font-weight: 400;
  line-height: 20px; /* 142.857% */
  text-transform: uppercase;
}

.login-container_mobile {
  .policy {
    font-size: 12px;
    line-height: 18px;
  }
}

.showBr {
  display: none;
}

@media screen and (max-width: 413px) {
  .showBr {
    display: block;
  }
}
</style>
