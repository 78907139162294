import { GtMessage } from '@speedshop/template';

export const asyncGtMessage = options =>
  new Promise(resolve => {
    GtMessage({
      ...options,
      onClose: () => {
        options.onClose && options.onClose();
        resolve(1);
      }
    });
  });
