<template>
  <div class="ua-complete-userinfo" v-loading="loading">
    <GtCheckboxGroup v-model="checkedhobbies" @change="handleCheckedhobbiesChange">
      <GtCheckbox v-for="hobby in hobbyListDD.HOBBY_LIST" :key="hobby.value" :label="hobby.value">
        <span>
          {{ hobby.label }}
        </span>
        <svg
          v-if="checkedhobbies.includes(hobby.value)"
          width="13"
          height="12"
          viewBox="0 0 13 12"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M11.5 2.5L4.835 9.5L1.5 6"
            stroke="#14141E"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
        </svg>
        <svg
          v-if="!checkedhobbies.includes(hobby.value)"
          width="13"
          height="12"
          viewBox="0 0 13 12"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M11.5 2.5L4.835 9.5L1.5 6"
            stroke="#eee"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
        </svg>
      </GtCheckbox>
    </GtCheckboxGroup>
  </div>
</template>
  
  <script>
import { GtCheckboxGroup, GtCheckbox } from '@speedshop/template';
import { queryDataDictionary } from '@speedshop/sdk-api/src/utils/common.ts';
import { useAccount } from '@speedshop/sdk-api';
import { updateCustomerAttribute } from '@/graphql/common.gql';
import { getUserInfo } from '@/graphql/user.gql';

const __ATTRIBUTE_CODE = 'HOBBY';
export default {
  name: 'MyHobby',
  components: {
    GtCheckboxGroup,
    GtCheckbox
  },
  data() {
    return {
      userInfo: {},
      loading: false,
      accountInstance: null,
      attributeAddressList: [],
      checkedhobbiesName: '',
      checkedhobbies: [],
      hobbyListDD: {
        HOBBY_LIST: [],
        HOBBY_LIST_MAP: {}
      }
    };
  },
  computed: {
    currentHobbies() {
      return {
        checkedhobbies: this.checkedhobbies,
        checkedhobbiesName: this.checkedhobbiesName
      };
    }
  },
  async created() {
    this.accountInstance = useAccount();
  },
  async mounted() {
    await this.init();
  },
  methods: {
    async init() {
      let {
        data: { userInfo }
      } = await this.accountInstance.gqlClient.query(getUserInfo);
      this.userInfo = userInfo;
      await this.ddCodesInit();
      await this.initHobbyInfo(userInfo);
    },

    async ddCodesInit() {
      this.hobbyListDD = await queryDataDictionary(['HOBBY_LIST'], this.accountInstance.gqlClient);
    },

    handleCheckedhobbiesChange(value) {
      this.$set(this, 'checkedhobbies', [...value]);
      this.setHobbyInfo();
    },

    async handleConfirmSubscriptionInfo() {
      try {
        this.loading = true;
        await this.setHobbyInfo();
        await this.init();
        this.loading = false;
      } catch (error) {
        this.loading = false;
      } finally {
        this.loading = false;
      }
    },

    initHobbyInfo(params) {
      let customAttributeList =
        params && params.customAttributeList && params.customAttributeList.length > 0
          ? params.customAttributeList
          : (this.$store.state.accountInfo && this.$store.state.accountInfo.customAttributeList) ||
            [];
      customAttributeList.forEach(v => {
        if (v.attributeKey === __ATTRIBUTE_CODE) {
          this.attributeAddressList = v.attributeValue ? JSON.parse(v.attributeValue) : [];
        }
      });

      this.checkedhobbies = [...this.attributeAddressList.map(v => v.value)];

      this.matchHobbyName();

        return {
          checkedhobbies: this.checkedhobbies,
          checkedhobbiesName: this.checkedhobbiesName
        }
    },

    async setHobbyInfo() {
      this.$set(this, 'checkedhobbies', [...this.checkedhobbies]);
      this.matchHobbyName();
   
      const data = this.checkedhobbies.map(v => {
        return {
          value: v,
          name: this.hobbyListDD.HOBBY_LIST_MAP[v]
        };
      });

      return data;
    },

    matchHobbyName(){
      let checkedhobbiesName = Array.isArray(this.hobbyListDD.HOBBY_LIST)
        ? this.hobbyListDD.HOBBY_LIST.filter(v => this.checkedhobbies.includes(v.value))
            .map(v => v.label)
            .join('、')
            .slice(0)
        : '';

      this.$set(this, 'checkedhobbiesName', checkedhobbiesName);
      return checkedhobbiesName;
    },

    async submit(data) {
      let res = await this.accountInstance.gqlClient.query(updateCustomerAttribute, {
        input: {
          customerCode: this.userInfo.memberCode,
          attributeKey: __ATTRIBUTE_CODE,
          attributeValue: JSON.stringify(data)
        }
      });

      return res;
    },

     getCurrentHobbies(userInfo, checkedhobbies, checkedhobbiesName) {
      if (userInfo) {
       return  this.initHobbyInfo(userInfo);
      }
      return {
        checkedhobbies: checkedhobbies || this.checkedhobbies,
        checkedhobbiesName: checkedhobbiesName || this.checkedhobbiesName ||  this.matchHobbyName()
      };
    }
  }
};
</script>
  
  <style lang="scss">
.ua-complete-userinfo {
  display: flex;
  justify-content: space-between;
  padding: 0;
  color: #444;
  .gt-checkbox-group {
    margin-top: 0px;
    margin-bottom: 24px;
    max-width: 100%;
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    flex-wrap: wrap;
    gap: 8px;

    .gt-checkbox .el-checkbox__input .el-checkbox__inner {
      display: none;
    }

    .el-checkbox__label {
      display: flex;
      height: 38px;
      justify-content: space-between;
      align-items: center;
      flex: 1;
      color: var(--main-color-ua-dark-grey, #14141e);
      font-size: 12px !important;
      font-style: normal;
      font-weight: 400;
      line-height: 18px;
      text-transform: uppercase;
      padding-left: 0px !important;
      margin-left: 0px !important;
    }

    .gt-checkbox {
      display: flex;
      height: 38px;
      padding: 0px 16px;
      justify-content: center;
      align-items: center;
      width: calc(25% - 6px);
      margin: 0;
      border-radius: 1px;
      border: 1px solid var(--line-color-line-grey-02, #eee);
      background: var(--main-color-ua-white, #fff);

      &.is-checked {
        outline-offset: -2px;
        outline: 2px solid var(--main-color-ua-dark-grey, #14141e);
        background: var(--main-color-ua-white, #fff);
      }
    }
  }
}
</style>
<style lang="scss">
.login-container_mobile {
  .ua-complete-userinfo {
    display: flex;
    justify-content: space-between;
    padding: 0;
    color: #444;
    h4 {
      display: none;
    }
    .gt-checkbox-group {
      margin-top: 0px;
      margin-bottom: 24px;
      max-width: 100%;
      display: flex;
      justify-content: flex-start;
      align-items: flex-start;
      flex-wrap: wrap;
      gap: 8px;

      .gt-checkbox .el-checkbox__input .el-checkbox__inner {
        display: none;
      }

      .el-checkbox__label {
        display: flex;
        height: 38px;
        justify-content: space-between;
        align-items: center;
        flex: 1;
        color: var(--main-color-ua-dark-grey, #14141e);
        font-size: 12px;
        font-style: normal;
        font-weight: 400;
        line-height: 18px;
        text-transform: uppercase;
        padding-left: 0px;
      }

      .gt-checkbox {
        display: flex;
        height: 38px;
        padding: 0px 16px;
        justify-content: center;
        align-items: center;
        width: calc(33% - 6px);
        margin: 0;
        border-radius: 1px;
        border: 1px solid var(--line-color-line-grey-02, #eee);
        background: var(--main-color-ua-white, #fff);

        &.is-checked {
          outline: 2px solid var(--main-color-ua-dark-grey, #14141e);
          outline-offset: -2px;
          background: var(--main-color-ua-white, #fff);
        }
      }
    }
  }
}
</style>
  