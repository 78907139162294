<template>
  <div v-if="['loginByPhone', 'loginByAccount'].includes(loginActive)" class="change-type">
    <p>其他登录方式</p>
    <div
      class="icon-me"
      @click="loginActive = loginActive === 'loginByPhone' ? 'loginByAccount' : 'loginByPhone'"
    >
      <span class="switch-span" style="">
        <svg   v-if="active === 'loginByPhone'" t="1709170956204" class="icon-svg" viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg" p-id="17935" width="16" height="16"><path d="M640 563.072H384a266.666667 266.666667 0 0 0-266.666667 266.666667v113.28h789.333334v-113.237334a266.666667 266.666667 0 0 0-266.666667-266.666666z m0 64l9.258667 0.213333a202.666667 202.666667 0 0 1 193.408 202.496v49.237334H181.333333v-49.237334A202.666667 202.666667 0 0 1 384 627.114667h256z m-128-546.133333a202.666667 202.666667 0 1 0 0 405.333333 202.666667 202.666667 0 0 0 0-405.333333z m0 64a138.666667 138.666667 0 1 1 0 277.333333 138.666667 138.666667 0 0 1 0-277.333333z" p-id="17936"></path></svg>
        <svg v-else t="1709171247180" class="icon-svg" viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg" p-id="18084" width="16px" height="16px"><path d="M800 64h-576c-19.2 0-32 12.8-32 32v832c0 19.2 12.8 32 32 32h576c19.2 0 32-12.8 32-32v-832c0-19.2-12.8-32-32-32zM768 896H256V128h512v768z" fill="#14141E" p-id="18085"></path><path d="M416 832h192c19.2 0 32-12.8 32-32s-12.8-32-32-32h-192c-19.2 0-32 12.8-32 32s12.8 32 32 32zM448 256h128c19.2 0 32-12.8 32-32S595.2 192 576 192H448c-19.2 0-32 12.8-32 32s12.8 32 32 32z" fill="#14141E" p-id="18086"></path></svg>
      </span>
      <p>
        {{ active === 'loginByPhone' ? '账号密码登录' : '手机号码登录' }}
      </p>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';

export default {
  name: 'SwitchLogin',
  computed: {
    ...mapGetters(['isMobile', 'isGuest', 'isLogin'])
  },
  props: {
    active: {
      type: String,
      default: 'loginByPhone'
    }
  },
  watch: {
    active: {
      handler(val, oldval) {
        this.loginActive = val;
        const cur = val === 'loginByPhone' ? '账号密码登录' : '手机验证码登录';
        this.$trackHelp.track('registerPageClick', {
          button_name: '切换登录方式',
          register_method: cur,
          register_content: '注册登录'
        });
        sessionStorage.setItem('loginActive', val);
      }
    },
    loginActive: {
      handler(val, oldval) {
        this.$emit('update:active', val);
        sessionStorage.setItem('loginActive', val);
      }
    }
  },
  data() {
    return {
      loginActive: this.active
    };
  },
  methods: {}
};
</script>
<style lang="scss" scoped>
.change-type {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 42px auto 0 auto !important;
  border-top: 1px solid #ddd;
  gap: 24px;
  font-size: 14px;

  .switch-span {
    width: 48px;
    height: 48px;
    border-radius: 100%;
    border: 1px solid var(--line-color-line-grey-01, #ddd);
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
  }

  > p {
    height: 20px;
    background: #fff;
    margin-top: -10px;
    color: var(--secondary-color-ua-cool-grey-03);
    padding: 0 32px;
  }

  .icon-me {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 12px;
    color: var(--secondary-color-ua-cool-grey-04, #50505a);

    div {
      width: 64px;
      height: 64px;
      flex-shrink: 0;
      border-radius: 64px;
      border: 1px solid #ddd;
      background: #fff;
      display: flex;
      align-items: center;
      justify-content: center;

      img {
        width: 24px;
        height: 24px;
      }
    }
  }
}
</style>

<style lang="scss" type="mobile">
.login-container_mobile {
  .change-type {
    margin: 40px auto 0 !important;
    padding: 0 0 20px 0;
    font-size: 12px;
    line-height: 18px;

    .icon-me {
      .switch-span {
        width: 40px;
        height: 40px;

        img {
          width: 16px;
          height: 16px;
        }
      }
    }
  }
}
</style>
